<template>
  <div class="product-search">
    <b-form @submit.stop.prevent="$emit('handlerFilter', true)">
      <b-row>
        <b-col cols="12" md="4">
          <b-form-input size="lg" v-model="filter.arama" class="rounded-0 mr-1" placeholder="Arama Kriteri..." />
        </b-col>
        <b-col cols="12" md="4" v-if="bolgeler.length > 0">
          <treeselect
            :options="bolgeler"
            v-model="filter.bolge_k_no"
            :normalizer="normalizer"
            :flat="true"
            :sort-value-by="sortValueBy"
            :default-expand-level="1"
            noChildrenText="Alt Öge Yok."
            placeholder="Lütfen Lokasyon Seçiniz"
            aria-describedby="input-1-live-feedback"
            class="rounded-0"
            :multiple="true"
            :max-height="200"
          />
        </b-col>
        <b-col cols="12" md="4">
          <v-select
            v-model="filter.siralama"
            :options="siralamalar"
            :reduce="(durum) => durum.id"
            label="title"
            :clearable="false"
            placeholder="Siralama..."
            class="invoice-filter-select d-block select-size-lg"
          />
        </b-col>
        <b-col md="4">
          <v-select
            v-model="filter.statu"
            :options="statuler"
            :reduce="(durum) => durum.id"
            label="title"
            :clearable="true"
            placeholder="Durum..."
            class="invoice-filter-select d-block select-size-lg"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-button type="submit" block variant="info" size="lg" class="rounded-0">
            <i class="fad fa-filter"></i> FİLTRE UYGULA
          </b-button>
        </b-col>
        <b-col cols="12" md="6">
          <b-button block variant="danger" size="lg" class="rounded-0" @click="$emit('filtered', false)">
            <i class="fad fa-window-close"></i> TEMİZLE
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import { defineComponent, computed, ref } from '@vue/composition-api';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default defineComponent({
  components: { vSelect, Treeselect },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.bolgeler = computed(() => store.getters.getBolgeler);

    expo.sortValueBy = ref('INDEX');

    expo.normalizer = (node) => {
      return {
        id: node.k_no && node.k_no,
        label: node.icerik != undefined && node.icerik[expo.defaultDil.value].baslik,
        children: node.children != undefined && node.children.length > 0 && node.children,
      };
    };

    expo.normalizerVitrin = (node) => {
      return {
        id: node.id,
        label: node.title,
      };
    };

    expo.siralamalar = ref([
      { id: 'baslik_az', title: 'Hotel Adı ( A-Z ) Sırala' },
      { id: 'baslik_za', title: 'Hotel Adı  ( Z-A ) Sırala' },
      { id: 'once_eklenen', title: 'Önce Eklenen' },
      { id: 'sonra_eklenen', title: 'Sonra Eklenen' },
    ]);

    expo.statuler = ref([
      { id: true, title: 'Yayında' },
      { id: false, title: 'Yayın Dışı' },
    ]);

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
