<template>
  <div class="products">
    <div class="overlay" :class="{ active: filtered }" @click="$emit('filtered', false)" />
    <div class="product-header" :class="{ active: filtered }">
      <Filtrele :filter="filter" @filtered="handlerTemizle" @handlerFilter="handlerFilter" />
    </div>
    <div class="product-body">
      <b-alert v-if="!rows.length" show variant="warning" class="text-center m-2">
        <h5>Bilgi</h5>
        <hr />
        <p>Gösterilecek veri yok</p>
      </b-alert>
      <div v-else>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :line-numbers="true"
          :pagination-options="{
            enabled: true,
            perPage: 100,
          }"
          styleClass="vgt-table striped bordered condensed"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'bolge_adi'">
              {{ props.row.bolge[defaultDil].baslik }}
              <!-- <span class="bg-info text-white mr-1 p-1" v-for="(bol, index) in props.row.bolge" :key="index">
            {{ bol.icerik[defaultDil].baslik }}
          </span> -->
            </span>
            <span v-else-if="props.column.field === 'hotel_adi'" @click="handlerUpdate(props.row)" style="cursor: pointer">
              {{ props.row.icerik[defaultDil].baslik }}
            </span>
            <span v-else-if="props.column.field === 'telefon'">
              {{ props.row.telefon }}
            </span>

            <span v-else-if="props.column.field === 'statu'">
              <b-form-checkbox
                class="custom-control-info"
                v-model="props.row.statu"
                switch
                @change="handlerStatu(props.row.k_no, $event)"
                size="lg"
              />
            </span>
            <span v-else-if="props.column.field === 'action'" class="float-right px-1">
              <b-button-group size="sm" class="pb-25">
                <b-button class="rounded-0" variant="warning" @click="handlerUpdate(props.row)">
                  <i class="fad fa-edit" />
                </b-button>
                <b-button variant="danger" class="rounded-0" @click="handlerRemove(props.row.k_no)">
                  <i class="fad fa-trash" />
                </b-button>
              </b-button-group>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom">
            <Pagination
              :urunler="rows"
              :count="count"
              :filter="filter"
              @handlerPageSizeChange="handlerPageSizeChange($event)"
              @handlerPageChange="handlerPageChange($event)"
            />
          </template>
        </vue-good-table>
      </div>
      <HotelEkle :updateData="updateData" :openModal="openModal" @closeModal="closeModal($event)" />
      <HotelApiEkle :openModal="openApiModal" @closeModal="$emit('closeApiModal', $event)" />
    </div>
  </div>
</template>
<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import BTableCard from '@/components/cards/BTableCard.vue';
import HotelEkle from './HotelEkle.vue';
import HotelApiEkle from './HotelApiEkle.vue';
import Pagination from './component/Pagination';
import Filtrele from './component/Filtrele.vue';
import { defineComponent, ref, computed, toRefs, watch } from '@vue/composition-api';
import axiosIns from '@/libs/axios';
import { useRouter } from '@/libs/utils';
export default defineComponent({
  components: {
    vSelect,
    BTableCard,
    BTableCard,
    VueGoodTable,
    HotelEkle,
    HotelApiEkle,
    Pagination,
    Filtrele,
  },
  props: {
    filtered: [Boolean],
    show: [Boolean],
    newAdd: [Boolean],
    openApiModal: [Boolean],
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { router, route } = useRouter();
    const { newAdd, filtered } = toRefs(props);
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.perPageOptions = ref([20, 40, 50, 80, 100]);
    expo.openModal = ref(false);
    expo.updateData = ref({});
    expo.count = ref(0);
    expo.filter = ref({
      page: 0,
      page_size: 20,
    });
    expo.columns = ref([
      {
        label: 'Bölge Adı',
        field: 'bolge_adi',
      },
      {
        label: 'Hotel Adı',
        field: 'hotel_adi',
      },
      {
        label: 'Telefon',
        field: 'telefon',
      },
      {
        label: 'Durum',
        field: 'statu',
        width: '10%',
        thClass: 'text-right',
        thClass: 'text-right',
      },
      {
        label: 'İşlemler',
        field: 'action',
        width: '10%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
    ]);
    expo.rows = ref([]);

    const handlerFetchAllData = async () => {
      context.emit('show', true);
      route.value.query.page = route.value.query.page ? parseInt(route.value.query.page) : 1;
      route.value.query.page_size = route.value.query.page_size ? parseInt(route.value.query.page_size) : 20;

      await store.dispatch('hotelListele', route.value.query).then((res) => {
        if (res.data.success) {
          expo.rows.value = res.data.data;
          expo.count.value = res.data.count;
          context.emit('total', expo.count.value);
        }
      });
      context.emit('show', false);
    };

    expo.handlerPageSizeChange = (event) => {
      router.push({
        query: { ...route.value.query, page_size: event },
      });
    };

    expo.handlerPageChange = (event) => {
      router.push({
        query: { ...route.value.query, page: event },
      });
    };

    expo.handlerUpdate = (event) => {
      expo.updateData.value = event;
      expo.openModal.value = true;
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('hotelSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme Başarılı', { position: 'bottom-left' });
            }
          });
        }
      });
    };

    expo.handlerFilter = () => {
      let query = {};

      if (expo.filter.value.bolge_k_no.length > 0) {
        let selected = expo.filter.value.bolge_k_no.toString();
        query = { ...route.value.query, bolge_k_no: selected };
      } else {
        delete query.bolge_k_no;
      }

      if (expo.filter.value.siralama) {
        query = { ...route.value.query, siralama: expo.filter.value.siralama };
      } else {
        delete query.siralama;
      }

      if (expo.filter.value.statu) {
        query = { ...route.value.query, statu: expo.filter.value.statu };
      } else {
        delete query.statu;
      }

      if (expo.filter.value.arama) {
        query = { ...route.value.query, arama: expo.filter.value.arama };
      } else {
        delete query.arama;
      }

      router.push({
        query: { ...query },
      });

      context.emit('filtered', false);
    };

    expo.handlerTemizle = () => {
      router.push({
        query: {},
      });

      expo.filter.value = {
        arama: null,
        bolge_k_no: [],
        siralama: null,
        statu: null,
      };
    };

    expo.handlerStatu = async (k_no, event) => {
      if (k_no != null) {
        await axiosIns.post('/uyeler/uye-statu-guncelle', { k_no, statu: event }).then((res) => {
          if (res.data.success === true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
          }
        });
      }
    };

    expo.closeModal = (event) => {
      context.emit('closeEvent', event);
      expo.openModal.value = event;
    };

    watch(
      newAdd,
      (val) => {
        if (val == true) {
          expo.openModal.value = val;
        }
      },
      { deep: true }
    );

    watch(
      route,
      (val) => {
        if (val.hash != '#' && val.name == 'hotel-listele') {
          expo.filter.value = {
            arama: route.value.query.arama ? route.value.query.arama : null,
            bolge_k_no: route.value.query.bolge_k_no ? route.value.query.bolge_k_no.split(',') : [],
            siralama: route.value.query.siralama ? route.value.query.siralama : null,
            statu: route.value.query.statu ? route.value.query.statu : null,
          };
          handlerFetchAllData();
        }
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
