<template>
  <div>
    <b-modal
      :visible="openModal"
      @change="(val) => $emit('closeModal', false)"
      size="lg"
      class="rounded-0"
      centered
      title="Hotel Ekle"
      hide-footer
    >
      <validation-observer ref="observer" v-slot="{ handleSubmit }" v-if="form">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)" class="p-2" autocomplete="off">
          <b-card-text>
            <b-row>
              <b-col cols="12" v-if="bolgeler.length">
                <validation-provider name="Hotel Bölgesi" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Hotel Bölgesi" :class="{ 'is-invalid': !!validationContext.errors[0] }">
                    <treeselect
                      :options="bolgeler"
                      v-model="form.bolge_k_no"
                      :normalizer="bolgeNormalizer"
                      :flat="true"
                      :sort-value-by="sortValueBy"
                      :default-expand-level="1"
                      noChildrenText="Alt Öge Yok."
                      placeholder="Lütfen Lokasyon Seçiniz"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      :class="{ 'is-invalid border-danger': !!validationContext.errors[0] }"
                      class="rounded-0"
                      :multiple="false"
                      :max-height="200"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider name="Hotel Adı" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Hotel Adı">
                    <b-form-input
                      size="lg"
                      class="rounded-0"
                      v-model="form.icerik[defaultDil].baslik"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      @input="handlerBaslikChange($event)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Adres">
                  <b-form-input size="lg" class="rounded-0" ref="baslik" v-model="form.adres" />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Telefon">
                  <b-form-input
                    v-mask="'+90 ### ### ## ##'"
                    placeholder="+90 ### ### ## ##"
                    size="lg"
                    class="rounded-0"
                    ref="baslik"
                    v-model="form.telefon"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Kaç Yıldız">
                  <b-form-input size="lg" type="number" min="0" max="5" class="rounded-0" ref="baslik" v-model="form.yildiz" />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="İcon">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <i :class="`fad fa-${form.icon}`"></i>
                    </b-input-group-prepend>
                    <b-form-input size="lg" class="rounded-0" ref="baslik" v-model="form.icon" />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Durumu">
                  <b-form-checkbox switch size="lg" class="rounded-0 pt-2" ref="baslik" v-model="form.statu" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
          <b-card-footer class="bg-transparent">
            <b-button size="lg" squared variant="primary" class="float-right" type="submit" :disabled="show">
              <i class="fad fa-save" v-if="!show" />
              <b-spinner class="mr-25" small variant="light" v-else />
              {{ form.k_no == null ? 'KAYDET' : 'GÜNCELLE' }}
            </b-button>
          </b-card-footer>
        </b-form>
      </validation-observer>
      <!-- <template #modal-footer="{ ok }">
            <div class="w-100">
              <b-button size="lg" squared variant="primary" class="float-right" type="submit" @click="ok()" :disabled="show">
                <i class="fad fa-save" v-if="!show" />
                <b-spinner class="mr-25" small variant="light" v-else />
                {{ form.k_no == null ? 'KAYDET' : 'GÜNCELLE' }}
              </b-button>
            </div>
          </template> -->
    </b-modal>
  </div>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification/composition';
import { ref, toRefs, watch, computed } from '@vue/composition-api';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import { langs } from '@/libs/languages';

export default {
  components: {
    Treeselect,
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.bolgeler = computed(() => store.getters.getBolgeler);
    expo.show = ref(false);

    expo.sortValueBy = ref('INDEX');

    expo.bolgeNormalizer = (node) => {
      return {
        id: node.k_no && node.k_no,
        label: node.icerik != undefined && node.icerik[expo.defaultDil.value].baslik,
        children: node.children != undefined && node.children.length > 0 && node.children,
      };
    };

    const { updateData } = toRefs(props);

    expo.form = ref({
      k_no: null,
      bolge_k_no: null,
      icerik: {},
      adres: null,
      telefon: null,
      icon: 'hotel',
      statu: true,
    });

    const createIcerikDilUret = (event) => {
      langs.forEach((dil) => {
        expo.form.value.icerik[dil.lang] = {
          baslik: event,
        };
      });
    };

    createIcerikDilUret('');

    expo.handlerBaslikChange = (event) => {
      createIcerikDilUret(event);
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      expo.show.value = true;
      const base_url = expo.form.value.k_no == null ? 'hotelEkle' : 'hotelGuncelle';

      store
        .dispatch(base_url, expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            expo.show.value = false;
            if (expo.form.value.k_no == null) {
              expo.form.value = {
                k_no: null,
                bolge_k_no: [],
                hotel_adi: null,
                adres: null,
                telefon: null,
                icon: 'hotel',
                statu: true,
              };
              toast.success('Kayıt Başarılı', { position: 'bottom-left' });
            } else {
              store.commit('SET_HOTELLER_UPDATE', res.data.data);
              toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
            }
          }
        })
        .catch((err) => {
          expo.show.value = false;
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length) expo.form.value = updateData.value;
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
