<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }" v-if="form">
      <b-modal
        :visible="openModal"
        @change="(val) => $emit('closeModal', false)"
        size="lg"
        class="rounded-0"
        centered
        title="Hotel Api ile Ekle"
        @ok.prevent="handleSubmit(onSubmit)"
      >
        <b-card-text>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Şehir">
                <b-form-input size="lg" class="rounded-0" ref="baslik" v-model="form.city" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="İlçe">
                <b-form-input size="lg" class="rounded-0" ref="baslik" v-model="form.country" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Mahalle">
                <b-form-input size="lg" class="rounded-0" ref="baslik" v-model="form.street" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-text>
        <template #modal-footer="{ ok }">
          <div class="w-100">
            <b-button size="lg" squared variant="primary" class="float-right" type="submit" @click="ok()" :disabled="show">
              <i class="fad fa-save" v-if="!show" />
              <b-spinner class="mr-25" small variant="light" v-else />
              {{ form.k_no == null ? 'KAYDET' : 'GÜNCELLE' }}
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import { useToast } from 'vue-toastification/composition';
import { ref, toRefs, watch, computed } from '@vue/composition-api';
import { langs } from '@/libs/languages';
export default {
  components: {},
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.bolgeler = computed(() => store.getters.getBolgeler);
    expo.show = ref(false);

    expo.form = ref({
      city: null,
      country: null,
      street: null,
    });

    expo.handlerBaslikChange = (event) => {
      createIcerikDilUret(event);
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      expo.show.value = true;
      expo.form.value.lang = langs;
      store
        .dispatch('hotelApiEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Kayıt Başarılı', { position: 'bottom-left' });
            expo.show.value = false;
          }
        })
        .catch((err) => {
          expo.show.value = false;
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
